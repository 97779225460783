<template>
  <div style="min-height: 100vh; background-color: #f8f9fa">
    <div class="healthBox">
      <div style="font-size: 14px; color: #333333; line-height: 20px">
        请您务必仔细阅读如下内容，并在确认充分理解与同意后使用：
      </div>
      <div style="margin-top: 16px">
        <div class="display_flex">
          <van-checkbox v-model="agreementChecked" icon-size="14px" disabled @click="tip(1)"></van-checkbox>
          <span style="
              font-size: 14px;
              color: #1477fd;
              line-height: 20px;
              margin-left: 8px;
            " @click="toAgreement">《健康状况调查问卷知情同意书》</span>
        </div>
        <div class="display_flex" style="margin-top: 16px">
          <van-checkbox v-model="userRightsChecked" icon-size="14px" disabled @click="tip(2)"></van-checkbox>
          <span style="
              font-size: 14px;
              color: #1477fd;
              line-height: 20px;
              margin-left: 8px;
            " @click="toUserRights">《用户使用须知》</span>
        </div>
      </div>
    </div>

    <!-- 基本信息 -->
    <div>
      <div class="surveyBox">
        <div class="title">基本信息</div>
        <div class="inputBox">
          <div class="inputTitle">姓名</div>
          <van-field :readonly="isReadonly" v-model="name" maxlength="6" input-align="right" placeholder="请填写"
            :border="showBorder" />
          <div style="width: 14px"></div>
        </div>
        <div class="inputBox">
          <div class="inputTitle">身份证</div>
          <van-field :readonly="isReadonly ? 'readonly' : false" v-model="idCard" maxlength="18" input-align="right"
            placeholder="请填写" :border="showBorder" />
          <div style="width: 14px"></div>
        </div>
        <div class="inputBox">
          <div class="inputTitle">手机号码</div>
          <van-field :readonly="isReadonly ? 'readonly' : false" v-model="mobile" maxlength="11" input-align="right"
            type="number" placeholder="请填写" :border="showBorder" />
          <div style="width: 14px"></div>
        </div>
      </div>
      <div class="tip">
        提示<span class="xing">*</span> 该手机号将用于报告查询，请如实填写
      </div>
      <div class="surveyBox" style="margin-top: 0">
        <div class="inputBox">
          <div class="inputTitle">邮箱地址</div>
          <van-field :readonly="isReadonly ? 'readonly' : false" v-model="emailAddress" input-align="right"
            placeholder="请填写" :border="showBorder" />
          <div style="width: 14px"></div>
        </div>
      </div>
      <div class="tip">
        提示<span class="xing">*</span> 该邮箱地址将用于接收报告文件，可选填
      </div>
      <div class="surveyBox" style="margin-top: 0">
        <div class="inputBox">
          <div class="inputTitle">生日</div>
          <van-field v-model="birthday" readonly @click="chooseBirthday" input-align="right" placeholder="请选择"
            :border="showBorder" />
          <van-icon v-if="!isReadonly" name="arrow" size="14px" color="#999999" />
          <div v-else style="width: 14px"></div>
        </div>
        <div class="inputBox">
          <div class="inputTitle">性别</div>
          <div class="gender">
            <van-radio-group v-model="gender">
              <van-radio :disabled="isReadonly" name="1" icon-size="14px">男</van-radio>
              <van-radio :disabled="isReadonly" name="2" icon-size="14px">女</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="inputBox">
          <div class="inputTitle">身高（cm）</div>
          <van-field :readonly="isReadonly ? 'readonly' : false" v-model="height" input-align="right" type="number"
            placeholder="请输入" :border="showBorder" @input="inputHeight" />
          <div style="width: 14px"></div>
        </div>
        <div class="inputBox">
          <div class="inputTitle">体重（kg）</div>
          <van-field :readonly="isReadonly ? 'readonly' : false" v-model="weight" input-align="right" type="number"
            placeholder="请输入" :border="showBorder" @input="inputWeight" />
          <div style="width: 14px"></div>
        </div>
        <div class="inputBox">
          <div class="inputTitle">职业</div>
          <van-field :readonly="isReadonly ? 'readonly' : false" v-model="profession" maxlength="20" input-align="right"
            placeholder="请填写" :border="showBorder" />
          <div style="width: 14px"></div>
        </div>
      </div>
    </div>

    <!-- 健康评估 -->
    <div>
      <div class="surveyBox" style="padding-bottom: 8px">
        <div class="title">健康评估</div>
        <div class="problemCard" style="padding-bottom: 0">
          <div>1. 您自我评估目前的身体健康状况？</div>
          <div class="healthStatus">
            <van-radio-group v-model="questions[0]">
              <van-radio :disabled="isReadonly" name="1" icon-size="14px">非常好</van-radio>
              <van-radio :disabled="isReadonly" name="2" icon-size="14px">良好</van-radio>
              <van-radio :disabled="isReadonly" name="3" icon-size="14px">一般</van-radio>
              <van-radio :disabled="isReadonly" name="4" icon-size="14px">差</van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard" style="padding-bottom: 12px">
          <div>2. 您是否有药物过敏史？</div>
          <div class="drugAllergy">
            <van-radio-group v-model="questions[1]">
              <van-radio :disabled="isReadonly" name="1" icon-size="14px">
                <div class="display_flex" style="align-items: center">
                  有<input :readonly="isReadonly ? 'readonly' : false" type="text" class="otherInput"
                    style="margin-left: 5px; margin-top: 0" v-model="remark[1]" />
                </div>
              </van-radio>
              <van-radio :disabled="isReadonly" name="2" icon-size="14px" @click="chooseOther(1)">没有</van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard" style="padding-bottom: 12px">
          <div>3. 您是否有食物过敏史或禁忌症？</div>
          <div class="foodAllergy">
            <van-radio-group v-model="questions[2]">
              <van-radio :disabled="isReadonly" name="1" icon-size="14px">
                <div class="display_flex" style="align-items: flex-start">
                  有<input :readonly="isReadonly ? 'readonly' : false" type="text" class="otherInput"
                    style="margin-left: 5px" v-model="remark[2]" />
                </div>
              </van-radio>
              <van-radio :disabled="isReadonly" name="2" icon-size="14px" @click="chooseOther(2)">没有</van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>
            4.
            您在近期是否受到情绪困扰或有重大压力，并已影响到您的精神状况和心理状态？
          </div>
          <div class="stress">
            <van-radio-group v-model="questions[3]">
              <van-radio :disabled="isReadonly" name="1" icon-size="14px">有严重影响</van-radio>
              <van-radio :disabled="isReadonly" name="2" icon-size="14px">有部分影响</van-radio>
              <van-radio :disabled="isReadonly" name="3" icon-size="14px">有轻微影响</van-radio>
              <van-radio :disabled="isReadonly" name="4" icon-size="14px">没有影响</van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>5. 您有下列疾病病史吗？（可多选）</div>
          <div class="diseaseHistory">
            <van-checkbox-group v-model="questions[4]">
              <van-checkbox :disabled="isReadonly" name="1" icon-size="14px"
                @click="notEmpty(4, '1', '8')">肝炎</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="2" icon-size="14px"
                @click="notEmpty(4, '2', '8')">结核病</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="3" icon-size="14px"
                @click="notEmpty(4, '3', '8')">糖尿病</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="4" icon-size="14px"
                @click="notEmpty(4, '4', '8')">肿瘤</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="5" icon-size="14px"
                @click="notEmpty(4, '5', '8')">心脏病</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="6" icon-size="14px"
                @click="notEmpty(4, '6', '8')">过敏</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="7" icon-size="14px"
                @click="notEmpty(4, '7', '8')">自身免疫性疾病</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="8" icon-size="14px" @click="empty(4, '8')">无</van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>6. 您最近有以下症状吗（近1个月以内）</div>
          <div class="symptom">
            <van-checkbox-group v-model="questions[5]">
              <van-checkbox :disabled="isReadonly" name="1" icon-size="14px"
                @click="notEmpty(5, '1', '13')">头疼</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="2" icon-size="14px"
                @click="notEmpty(5, '2', '13')">腰痛</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="3" icon-size="14px"
                @click="notEmpty(5, '3', '13')">牙疼</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="4" icon-size="14px"
                @click="notEmpty(5, '4', '13')">便秘</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="5" icon-size="14px"
                @click="notEmpty(5, '5', '13')">肩膀酸痛</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="6" icon-size="14px"
                @click="notEmpty(5, '6', '13')">食欲不振</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="7" icon-size="14px"
                @click="notEmpty(5, '7', '13')">尿频尿急</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="8" icon-size="14px"
                @click="notEmpty(5, '8', '13')">精神涣散</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="9" icon-size="14px"
                @click="notEmpty(5, '9', '13')">视物模糊</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="10" icon-size="14px"
                @click="notEmpty(5, '10', '13')">口腔溃疡</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="11" icon-size="14px"
                @click="notEmpty(5, '11', '13')">耳鸣</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="12" icon-size="14px" @click="chooseOther(5)">
                其它
              </van-checkbox>
              <input :readonly="isReadonly || !symptomOther ? 'readonly' : false" type="text" class="otherInput"
                v-model="remark[5]" style="margin-right: calc(60vw - 160px)" />
              <van-checkbox :disabled="isReadonly" name="13" icon-size="14px" @click="empty(5, '13')">无</van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>7. 您现在是否患有以下疾病（可多选）</div>
          <div class="disease">
            <van-checkbox-group v-model="questions[6]">
              <van-checkbox :disabled="isReadonly" name="1" icon-size="14px"
                @click="notEmpty(6, '1', '10')">高血压</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="2" icon-size="14px"
                @click="notEmpty(6, '2', '10')">高血脂</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="3" icon-size="14px"
                @click="notEmpty(6, '3', '10')">高血糖</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="4" icon-size="14px"
                @click="notEmpty(6, '4', '10')">肝炎</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="5" icon-size="14px"
                @click="notEmpty(6, '5', '10')">心脏病</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="6" icon-size="14px"
                @click="notEmpty(6, '6', '10')">糖尿病</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="7" icon-size="14px"
                @click="notEmpty(6, '7', '10')">感染性疾病</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="8" icon-size="14px"
                @click="notEmpty(6, '8', '10')">自身免疫性疾病</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="9" icon-size="14px"
                @click="notEmpty(6, '9', '10')">过敏性疾病</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="10" icon-size="14px" @click="empty(6, '10')">无</van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>8. 您在近四周之内是否有受过下列类型创伤？（可多选）</div>
          <div class="trauma">
            <van-checkbox-group v-model="questions[7]">
              <van-checkbox :disabled="isReadonly" name="1" icon-size="14px"
                @click="notEmpty(7, '1', '6')">骨折</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="2" icon-size="14px"
                @click="notEmpty(7, '2', '6')">表皮或黏膜破损</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="3" icon-size="14px"
                @click="notEmpty(7, '3', '6')">烧伤</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="4" icon-size="14px"
                @click="notEmpty(7, '4', '6')">烫伤</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="5" icon-size="14px"
                @click="notEmpty(7, '5', '6')">脏器损伤</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="6" icon-size="14px" @click="empty(7, '6')">无</van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>9. 吸烟史情况</div>
          <div class="stress">
            <van-radio-group v-model="questions[8]">
              <van-radio :disabled="isReadonly" name="1" icon-size="14px">从不吸烟</van-radio>
              <van-radio :disabled="isReadonly" name="2" icon-size="14px">经常吸烟</van-radio>
              <van-radio :disabled="isReadonly" name="3" icon-size="14px">过去吸烟，已戒烟</van-radio>
              <van-radio :disabled="isReadonly" name="4" icon-size="14px">有二手烟问题</van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>10. 饮酒情况</div>
          <div class="stress">
            <van-radio-group v-model="questions[9]">
              <van-radio :disabled="isReadonly" name="1" icon-size="14px">从不饮酒</van-radio>
              <van-radio :disabled="isReadonly" name="2" icon-size="14px">过去饮酒，已戒酒</van-radio>
              <van-radio :disabled="isReadonly" name="3" icon-size="14px">偶尔饮酒 (每周≤1次)</van-radio>
              <van-radio :disabled="isReadonly" name="4" icon-size="14px">规律饮酒（每周＞1次）</van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>11. 您平均每天睡眠时间在几个小时?</div>
          <div class="stress">
            <van-radio-group v-model="questions[10]">
              <van-radio :disabled="isReadonly" name="1" icon-size="14px">≥10小时</van-radio>
              <van-radio :disabled="isReadonly" name="2" icon-size="14px">7小时-10小时</van-radio>
              <van-radio :disabled="isReadonly" name="3" icon-size="14px">＜7小时</van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>12. 您的睡眠质量如何?</div>
          <div class="sleepQuality">
            <van-radio-group v-model="questions[11]">
              <van-radio :disabled="isReadonly" name="1" icon-size="14px">好</van-radio>
              <van-radio :disabled="isReadonly" name="2" icon-size="14px">一般</van-radio>
              <van-radio :disabled="isReadonly" name="3" icon-size="14px">多梦易醒</van-radio>
              <van-radio :disabled="isReadonly" name="4" icon-size="14px">失眠</van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>13. 您每周的健身运动频率？</div>
          <div class="stress">
            <van-radio-group v-model="questions[12]">
              <van-radio :disabled="isReadonly" name="1" icon-size="14px">基本不运动</van-radio>
              <van-radio :disabled="isReadonly" name="2" icon-size="14px">1 - 2次/周</van-radio>
              <van-radio :disabled="isReadonly" name="3" icon-size="14px">3次以上/周</van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>14. 您的饮食嗜好及口味？（可多选）</div>
          <div class="diet">
            <van-checkbox-group v-model="questions[13]">
              <van-checkbox :disabled="isReadonly" name="1" icon-size="14px">嗜盐</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="2" icon-size="14px">嗜油</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="3" icon-size="14px">嗜糖
              </van-checkbox>
              <van-checkbox :disabled="isReadonly" name="4" icon-size="14px">嗜辣</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="5" icon-size="14px">荤素均衡</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="6" icon-size="14px">荤食为主</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="7" icon-size="14px">素食为主</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="8" icon-size="14px" @click="chooseOther(13)">
                其它
              </van-checkbox>
              <input :readonly="isReadonly || !dietOther ? 'readonly' : false" type="text" class="otherInput"
                v-model="remark[13]" />
            </van-checkbox-group>
          </div>
        </div>
      </div>
      <div class="surveyBox">
        <div class="problemCard">
          <div>15. 您常补充的营养品或保健品有哪些?（可多选）</div>
          <div class="nutrition">
            <van-checkbox-group v-model="questions[14]">
              <van-checkbox :disabled="isReadonly" name="2" icon-size="14px"
                @click="notEmpty(14, '2', '1')">钙剂</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="3" icon-size="14px" @click="notEmpty(14, '3', '1')">铁剂
              </van-checkbox>
              <van-checkbox :disabled="isReadonly" name="4" icon-size="14px"
                @click="notEmpty(14, '4', '1')">蜂胶</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="5" icon-size="14px"
                @click="notEmpty(14, '5', '1')">卵磷脂</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="6" icon-size="14px"
                @click="notEmpty(14, '6', '1')">鱼肝油</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="7" icon-size="14px"
                @click="notEmpty(14, '7', '1')">蛋白粉</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="8" icon-size="14px"
                @click="notEmpty(14, '8', '1')">复合维生素矿物质</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="9" icon-size="14px"
                @click="notEmpty(14, '9', '1')">植物纤维</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="10" icon-size="14px"
                @click="notEmpty(14, '10', '1')">深海鱼油</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="11" icon-size="14px"
                @click="notEmpty(14, '11', '1')">胶原蛋白</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="12" icon-size="14px"
                @click="notEmpty(14, '12', '1')">维生素C</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="13" icon-size="14px"
                @click="notEmpty(14, '13', '1')">维生素E</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="14" icon-size="14px"
                @click="notEmpty(14, '14', '1')">维生素D</van-checkbox>
              <van-checkbox :disabled="isReadonly" name="15" icon-size="14px" @click="chooseOther(14)">
                其它
              </van-checkbox>
              <input :readonly="isReadonly || !nutritionOther ? 'readonly' : false" type="text" class="otherInput"
                v-model="remark[14]" style="margin-right: calc(60vw - 160px)" />
              <van-checkbox :disabled="isReadonly" name="1" icon-size="14px" @click="empty(14, '1')">无</van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isReadonly" style="height: 20px; width: 100%"></div>
    <div v-else>
      <div style="height: 92px; width: 100%"></div>
      <div class="bottom">
        <button class="bottomButton" @click="achieve">完成</button>
      </div>
    </div>
    <van-action-sheet v-model="birthdayShow" :close-on-click-overlay="closeOverlay">
      <van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate"
        @confirm="birthConfirm" @cancel="birthCancel" visible-item-count="4" confirm-button-color="red"
        :formatter="formatter" />
    </van-action-sheet>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      showBorder: false,
      name: "", //用户名
      idCard: "", //身份证号
      mobile: "", //手机号
      emailAddress: "", //邮箱地址
      birthday: "", //生日
      gender: "", //性别
      height: null, //身高
      weight: null, //体重
      profession: "", //职业
      questions: new Array(15), //选项
      remark: new Array(15), //其他
      symptomOther: false, //未点击其他，不可填写
      diseaseOther: false,
      dietOther: false,
      nutritionOther: false,
      agreementChecked: false, //知情同意书是否勾选
      userRightsChecked: false, //用户需知是否勾选
      birthdayShow: false, //是否展示生日选择下拉框
      currentDate: new Date(), //当前日期
      maxDate: new Date(),
      closeOverlay: true,
      minDate: new Date("1900/1/1"),
      formatter(type, value) {
        if (type === "year") {
          return `${value}年`;
        }
        if (type === "month") {
          return `${value}月`;
        }
        if (type === "day") {
          return `${value}日`;
        }
        return value;
      },
      isReadonly: false, //是否只读
    };
  },
  created() { },
  mounted() {
    // 知情书和用户协议书是否已读,只读页面默认已读
    if (
      localStorage.getItem("isReadonly") == "true" ||
      localStorage.getItem("agreement") == 1
    ) {
      this.agreementChecked = true;
    }
    if (
      localStorage.getItem("isReadonly") == "true" ||
      localStorage.getItem("userRights") == 1
    ) {
      this.userRightsChecked = true;
    }

    // 页面是否只可读
    this.isReadonly =
      localStorage.getItem("isReadonly") == "false" ? false : true;

    // 是否已经编写
    let healthSurvey = JSON.parse(localStorage.getItem("healthSurvey"));
    if (healthSurvey != undefined && healthSurvey != null) {
      this.name = healthSurvey.name;
      this.idCard = healthSurvey.idCard;
      this.emailAddress = healthSurvey.emailAddress;
      this.mobile = healthSurvey.mobile;
      this.birthday = healthSurvey.birthday;
      this.gender = healthSurvey.gender + "";
      this.height = healthSurvey.height;
      this.weight = healthSurvey.weight;
      this.profession = healthSurvey.profession;
      let questions = [];
      let remark = [];
      healthSurvey.questionnaire.map((item) => {
        if (item.multiterm == 1) {
          // 单选题
          questions.push(item.answer[0]);
        } else {
          questions.push(item.answer);
        }
        remark.push(item.remark);
      });
      this.questions = questions;
      this.remark = remark;
    }
  },
  computed: {},
  methods: {
    saveDate() {
      let arr = this.handleData();
      let healthSurveyData = {
        name: this.name,
        idCard: this.idCard,
        mobile: this.mobile, //手机号
        emailAddress: this.emailAddress, //邮箱地址
        birthday: this.birthday, //生日
        gender: this.gender, //性别
        height: this.height, //身高
        weight: this.weight, //体重
        profession: this.profession, //职业
        questionnaire: arr,
      };
      localStorage.setItem("healthSurvey", JSON.stringify(healthSurveyData)); //表示已经填写健康问卷调查
    },
    // 去知情同意书页面
    toAgreement() {
      this.saveDate();
      this.$router.push("/agreement");
    },
    // 去用户需知页面
    toUserRights() {
      this.saveDate();
      this.$router.push("/userRights");
    },
    // 选择生日
    chooseBirthday() {
      if (!this.isReadonly) {
        this.birthdayShow = true;
      }
    },
    // 确定
    birthConfirm() {
      this.birthdayShow = false;
      let year = this.currentDate.getFullYear();
      let month =
        this.currentDate.getMonth() + 1 < 10
          ? "0" + (this.currentDate.getMonth() + 1)
          : this.currentDate.getMonth() + 1;
      let day =
        this.currentDate.getDate() < 10
          ? "0" + this.currentDate.getDate()
          : this.currentDate.getDate();
      this.birthday = year + "-" + month + "-" + day;
    },
    birthCancel() {
      this.birthdayShow = false;
    },
    //数据校验
    dataCheck() {
      // 校验知情同意书
      if (localStorage.getItem("agreement") != 1) {
        Toast("请同意健康知情书");
        return false;
      }
      if (localStorage.getItem("userRights") != 1) {
        Toast("请同意用户使用须知");
        return false;
      }

      let mobilereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      let emailreg =
        /^[0-9a-zA-Z][a-z0-9A-Z._-]{1,}@[a-zA-Z0-9-]{1,}[a-zA-Z0-9].[a-z.]{1,}[a-z]$/;
      if (!this.name.trim()) {
        Toast("请填写姓名");
        return false;
      } else if (!this.idCard.trim()) {
        Toast("请填写身份证号");
        return false;
      } else if (this.idCard.trim() && this.idCard.length != 18) {
        Toast("身份证号为18位字符");
        return false;
      } else if (!this.mobile.trim()) {
        Toast("请填写手机号");
        return false;
      } else if (this.mobile.trim() && !mobilereg.test(this.mobile.trim())) {
        Toast("手机号格式不对");
        return false;
      }
      // else if (!this.emailAddress.trim()) {
      //     Toast('请填写邮箱地址');
      //     return false
      // }
      else if (
        this.emailAddress.trim() &&
        !emailreg.test(this.emailAddress.trim())
      ) {
        Toast("邮箱地址格式不对");
        return false;
      } else if (!this.birthday) {
        Toast("请填写生日");
        return false;
      } else if (this.birthday) {
        let bir =
          this.idCard.substring(6, 10) +
          "-" +
          this.idCard.substring(10, 12) +
          "-" +
          this.idCard.substring(12, 14);
        if (bir !== this.birthday) {
          Toast("生日与身份证上不匹配");
          return false;
        }
      }

      if (!this.gender) {
        Toast("请选择性别");
        return false;
      } else if (!this.height.trim()) {
        Toast("请填写身高");
        return false;
      } else if (this.height.trim() && this.height.trim() > 300) {
        Toast("身高输入0-300间");
        return false;
      } else if (!this.weight.trim()) {
        Toast("请填写体重");
        return false;
      } else if (!this.profession.trim()) {
        Toast("请填写职业");
        return false;
      }

      for (let i = 0; i < 15; i++) {
        if (!this.questions[i] || this.questions[i].length == 0) {
          Toast(`第${i + 1}题未选择，请完成选择`);
          return false;
        }
      }
      return true;
    },
    // 健康评估题目处理
    handleData() {
      let arr = [];
      this.questions.map((item, index) => {
        if (typeof item == "string") {
          // 单选
          arr.push({
            titleNumber: index + 1,
            answer: [item],
            remark: this.remark[index],
            multiterm: 1,
          });
        } else {
          //多选
          arr.push({
            titleNumber: index + 1,
            answer: item,
            remark: this.remark[index],
            multiterm: 2,
          });
        }
      });
      return arr;
    },
    //完成
    achieve() {
      if (this.dataCheck()) {
        this.saveDate();
        localStorage.setItem("isHealthAchieve", 1);
        this.$router.go(-1);
      }
    },
    // 提示需要同意知情书
    tip(index) {
      if (!this.agreementChecked) {
        if (index == 1) {
          Toast("请先阅读相关协议");
        }
      }
      if (!this.userRightsChecked) {
        if (index == 2) {
          Toast("请先阅读相关协议");
        }
      }
    },
    // 判断是否选择其他选项
    chooseOther(index) {
      let arr = this.questions[index];
      // 单选题
      if (index == 1) {
        if (arr == "2") {
          this.$set(this.remark, 1, "");
        }
      } else if (index == 2) {
        if (arr == "2") {
          this.$set(this.remark, 2, "");
        }
      }

      // 多选题
      if (index == 5) {
        // 第六题
        if (arr.includes("12")) {
          this.symptomOther = true;
        } else {
          this.$set(this.remark, 5, "");
          this.symptomOther = false;
        }
        this.notEmpty(index, "12", "13");
      } else if (index == 13) {
        if (arr.includes("8")) {
          this.dietOther = true;
        } else {
          this.$set(this.remark, 13, "");
          this.dietOther = false;
        }
      } else if (index == 14) {
        this.notEmpty(index, "15", "1");
        if (arr.includes("15")) {
          this.nutritionOther = true;
        } else {
          this.$set(this.remark, 14, "");
          this.nutritionOther = false;
        }
      }
    },
    // 点击无，取消其他选项
    empty(index, value) {
      if (!this.isReadonly) {
        this.$set(this.questions, index, [value]);
        if (index == 14) {
          // 清空其他，并且不能输入
          this.$set(this.remark, index, '');
          this.nutritionOther = false;
        } else if (index == 5) {
          this.$set(this.remark, index, '');
          this.symptomOther = false;
        }
      }
    },
    //点击其他非无，无选项取消
    notEmpty(index, value, emptyValue) {
      if (
        this.questions[index].includes(value) &&
        this.questions[index].includes(emptyValue)
      ) {
        this.questions[index].splice(
          this.questions[index].indexOf(emptyValue),
          1
        );
      }
    },
    // 输入身高校验
    inputHeight() {
      if (this.height == "-" || Number(this.height) < 0) {
        this.height = "";
      }
      if (
        this.height.toString().includes(".") &&
        this.height.toString().split(".")[1].length > 1
      ) {
        this.height = Math.floor(Number(this.height) * 10) / 10;
      }
    },
    inputWeight() {
      if (this.weight == "-" || Number(this.weight) < 0) {
        this.weight = "";
      }
      if (
        this.weight.toString().includes(".") &&
        this.weight.toString().split(".")[1].length > 1
      ) {
        this.weight = Math.floor(Number(this.weight) * 10) / 10;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.healthBox {
  padding: 8px 20px;
  background-color: #fff;
}

.surveyBox {
  padding: 0 16px;
  background-color: #fff;
  margin-top: 12px;

  .title {
    padding: 17px 0 5px 0;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    display: flex;
    align-items: center;
  }

  .title::before {
    content: "";
    width: 2px;
    height: 10px;
    background: #1477fd;
    border-radius: 1px;
    margin-right: 8px;
  }
}

.inputBox {
  height: 52px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;

  .inputTitle {
    font-size: 14px;

    color: #333333;
    width: 100px;
    line-height: 22px;
  }
}

.tip {
  font-size: 12px;

  color: #e02020;
  padding: 10px 16px;
}

.problemCard {
  font-size: 14px;

  color: #333333;
  line-height: 20px;
  padding-top: 12px;
}

.van-radio-group,
.van-checkbox-group {
  margin-top: 10px;
  justify-content: flex-start;
  flex-wrap: wrap;
  display: flex;
  white-space: nowrap;
}

::v-deep .van-checkbox__label,
::v-deep .van-radio__label {
  // min-width: 60px;
  display: inline-block;
  line-height: 40px;
  font-size: 14px;
  color: #333333;
}

.healthStatus .van-radio {
  width: 22vw;
}

.drugAllergy .van-radio,
.foodAllergy .van-radio,
.stress .van-radio {
  min-width: 45vw;
}

.diseaseHistory .van-checkbox {
  min-width: 22vw;
}

.diseaseHistory .van-checkbox:nth-child(7) {
  width: 46vw;
}

.symptom .van-checkbox,
.disease .van-checkbox,
.trauma .van-checkbox {
  width: 30vw;
}

.symptom .van-checkbox:nth-child(11),
.disease .van-checkbox:nth-child(8),
.trauma .van-checkbox:nth-child(2) {
  width: 60vw;
}

.symptom .van-checkbox:nth-child(12) {
  width: 55px;
}

.sleepQuality .van-radio {
  min-width: 22vw;
}

.diet .van-checkbox,
.nutrition .van-checkbox {
  width: 30vw;
}

.sleepQuality .van-radio:nth-child(3) {
  min-width: 25vw;
}

.diet .van-checkbox:nth-child(8) {
  width: 55px;
}

.nutrition .van-checkbox:nth-child(7),
.nutrition .van-checkbox:nth-child(13) {
  width: 60vw;
}

.nutrition .van-checkbox:nth-child(14) {
  width: 55px;
}

.gender {
  flex: 1;

  .van-radio-group {
    width: 110px;
    float: right;
    margin-right: 14px;
    justify-content: space-between;
  }
}

.van-cell {
  height: 52px;
  flex: 1;
  padding-left: 0 !important;
  padding-right: 0px !important;
  font-size: 14px;
  color: #333333;

  ::v-deep .van-field__body {
    height: 32px;
  }
}

::v-deep .van-picker__confirm {
  color: #1477fd;
}

::v-deep .van-checkbox__icon--checked .van-icon {
  color: #fff !important;
  background-color: #1989fa !important;
  border-color: #1989fa !important;
}

::v-deep .van-checkbox__icon--disabled .van-icon {
  color: #fff;
  background-color: #fff;
  border-color: rgb(200, 201, 204);
}

::v-deep .van-checkbox__label--disabled {
  color: #323233;
}

::v-deep .van-radio__icon--checked .van-icon {
  color: #fff !important;
  background-color: #1989fa !important;
  border-color: #1989fa !important;
}

::v-deep .van-radio__icon--disabled .van-icon {
  color: #fff;
  background-color: #fff;
  border-color: rgb(200, 201, 204);
}

::v-deep .van-radio__label--disabled {
  color: #323233;
}

.otherInput {
  width: 105px;
  height: 20px;
  line-height: 20px;
  border-top: 0;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom: 1px solid #e6e6e6;
  padding-left: 5px;
  padding-bottom: 0;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 14px;
  margin-top: 10px;
}

.xing {
  display: inline-block;
  vertical-align: middle;
  line-height: 17px;
  font-size: 12px;
}
</style>
